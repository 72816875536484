<template>
  <v-sheet elevation="3" class="pa-3">
    <v-row>
      <v-col cols="12" sm="6" md="3">
        <v-select
          :value="tableParams.expertType"
          :items="experts"
          label="Тип эксперта"
          item-value="value"
          item-text="title"
          @input="setField('expertType', $event)"
        />
      </v-col>

      <v-col cols="12" sm="6" md="3">
        <stocks-sectors
          :sector="tableParams.sector"
          empty-option
          :disabled="isNoExistSector"
          @change="setField('sector', $event)"
        />
      </v-col>

      <v-col cols="12" sm="6" md="3">
        <v-text-field
          label="Поиск по эксперту"
          :value="tableParams.search"
          clearable
          @input="setField('search', $event)"
        />
      </v-col>

      <v-col cols="12" sm="6" md="3">
        <v-text-field
          label="Поиск по компании"
          :value="tableParams.firmSearch"
          clearable
          @input="setField('firmSearch', $event)"
        />
      </v-col>

      <v-col cols="12" sm="6" lg="4" xl="3">
        <v-checkbox v-model="isRuStocks" label="По акциям РФ" />
      </v-col>
    </v-row>
  </v-sheet>
</template>

<script>
import { editorFields } from "~/mixins/multi-editor/editor-fields";

import StocksSectors from "~/components/stocks/sectors.vue";

export default {
  mixins: [editorFields],
  props: {
    tableParams: {
      type: Object,
      required: true
    }
  },
  data() {
    return {
      experts: [
        { value: "analyst", title: "Аналитики" },
        { value: "insider", title: "Инсайдеры" },
        { value: "blogger", title: "Блогеры" },
        { value: "hedgefund", title: "Менеджеры фондов" }
      ]
    };
  },
  computed: {
    isNoExistSector() {
      const { expertType } = this.tableParams;

      return expertType === "insider" || expertType === "hedgefund" ? true : false;
    },
    isRuStocks: {
      get() {
        return this.tableParams.forRuStocks;
      },
      set(value) {
        this.setField("forRuStocks", value ? value : null);
      }
    }
  },
  watch: {
    "tableParams.expertType"() {
      if (this.isNoExistSector) {
        this.setField("sector", "");
      }
    }
  },
  components: {
    StocksSectors
  }
};
</script>
