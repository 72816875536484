<template>
  <div>
    <h2 class="mb-4">Эксперты</h2>

    <experts-filters class="mb-5" :table-params="tableParams" @change-field="changeField" />

    <data-table
      ref="dataTable"
      url="experts"
      sort-by="expertRank"
      :sort-desc="false"
      :headers="headers"
      :table-params="tableParams"
    >
      <template v-slot:[`item.photo`]="{ item }">
        <div>
          <v-img class="rounded-circle" :src="item.photo" width="44" height="44" />
        </div>
      </template>

      <template v-slot:[`item.latestRatingDate`]="{ item }">
        {{ item.latestRatingDate | date }}
      </template>

      <template v-slot:[`item.sector`]="{ item }">
        {{ item.sector | sectors({ type: "short" }) }}
      </template>

      <template v-slot:[`item.overallRecommendations`]="{ item }">
        {{ item.overallRecommendations | number({ fractionDigits: 0 }) }}
      </template>

      <template v-slot:[`item.followersCount`]="{ item }">
        <div class="d-inline-flex">
          <v-text-field
            :key="item.id"
            :value="item.followersCount"
            :rules="[value => validateFollowersCount(value) || 'Недопустимое значение']"
            dense
            hide-details
            @change="updateFollowersCount(item, $event)"
          />
        </div>
      </template>

      <template v-slot:[`item.isActive`]="{ item }">
        {{ item.isActive | yesNo }}
      </template>

      <template v-slot:[`item.action`]="{ item }">
        <div class="d-flex">
          <v-btn icon color="accent" @click="editExpert(item)">
            <v-icon small>mdi-pencil</v-icon>
          </v-btn>
        </div>
      </template>
    </data-table>

    <v-divider class="my-6" />

    <div class="text-h6 mb-3">Новый аналитик</div>

    <experts-create-expert-form />
  </div>
</template>

<script>
import { ApiValidationError } from "~/core/api-validation-error";

import { editorForm } from "~/mixins/multi-editor/editor-form";

import ExpertsFilters from "~/components/experts/filters.vue";

import ExpertsCreateExpertForm from "~/components/experts/create-expert-form.vue";

export default {
  mixins: [editorForm],
  data() {
    return {
      headers: [
        { text: "Место", value: "expertRank", sortable: true, class: "nobr" },
        { text: "Аватарка", value: "photo", sortable: false },
        { text: "Имя", value: "name", sortable: true },
        { text: "Компания", value: "firmName", sortable: true },
        { text: "Сектор", value: "sector" },
        { text: "Кол-во прогнозов", value: "overallRecommendations", sortable: true },
        { text: "Дата посл. прогноза", value: "latestRatingDate", sortable: true, class: "nobr" },
        { text: "Подписчиков", value: "followersCount", sortable: true },
        { text: "Активный", value: "isActive", sortable: true, class: "nobr" },
        { text: "Действие", value: "action", sortable: false }
      ],
      tableParams: {
        expertType: "analyst",
        sector: "",
        firmSearch: "",
        search: "",
        forRuStocks: ""
      }
    };
  },
  methods: {
    refreshTable() {
      return this.$refs.dataTable.update();
    },
    changeField(payload) {
      this.commitChange(this.tableParams, payload);
    },
    validateFollowersCount(value) {
      return /^[0-9]+$/.test(value);
    },
    async updateFollowersCount(expert, followersCount) {
      if (!this.validateFollowersCount(followersCount)) return;

      try {
        await this.$axios.$patch(`experts/${expert.id}`, JSON.stringify({ followersCount }), {
          headers: {
            "Content-Type": "application/json"
          }
        });
      } catch (e) {
        const html = new ApiValidationError(e).toHtml();

        this.$snackbar.error("Не обновлено количество подписчиков" + html);
      }
    },
    editExpert(expert) {
      const route = this.$router.resolve({ name: "experts-id", params: { id: expert.id } });

      window.open(route.href, "_blank");
    }
  },
  components: {
    ExpertsFilters,
    ExpertsCreateExpertForm
  }
};
</script>
