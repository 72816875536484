<template>
  <v-form ref="form" v-model="isValidForm" autocomplete="off" @submit.prevent="createExpert">
    <v-row>
      <v-col cols="12" sm="6" lg="4">
        <select-invest-companies
          ref="selectInvestCompanies"
          :rules="[v => !!v || 'Не выбран инвест-дом']"
          :clearable="false"
          @change="setFirmId"
        />
      </v-col>
    </v-row>

    <v-text-field v-model="expert.firstName" label="Имя" required :rules="[v => !!v || 'Не введено имя']" />

    <v-text-field v-model="expert.lastName" label="Фамилия" required :rules="[v => !!v || 'Не введена фамилия']" />

    <v-text-field v-model="expert.followersCount" type="number" label="Количество подписчиков" />

    <v-row>
      <v-col cols="12" sm="6" lg="3" xl="3">
        <experts-create-media v-model="expert.photo" :expert="expert" />
      </v-col>
    </v-row>

    <v-col class="font-weight-regular" md="12">
      <v-checkbox v-model="expert.isForRuStocks" class="my-0" label="Принадлежность (по акциям РФ)" hide-details />
    </v-col>

    <div class="mt-4">
      <v-btn type="submit" color="primary mr-4" :loading="loading">Добавить эксперта</v-btn>
    </div>
  </v-form>
</template>

<script>
import { ApiValidationError } from "~/core/api-validation-error";
import { encodeFileToBase64 } from "~/core/helpers";

import SelectInvestCompanies from "./select-invest-companies";
import ExpertsCreateMedia from "./media";

const defaultExpertDto = {
  firmId: "",
  firstName: "",
  lastName: "",
  followersCount: 0,
  photo: null,
  isForRuStocks: false,
  expertType: "analyst"
};

Object.freeze(defaultExpertDto);

export default {
  data() {
    return {
      loading: false,
      expert: { ...defaultExpertDto },

      isValidForm: true
    };
  },
  computed: {
    createExpertDto() {
      return { ...this.expert };
    }
  },
  methods: {
    async createExpert() {
      if (!this.isValidForm) {
        this.$refs.form.validate();

        return;
      }

      this.loading = true;

      try {
        this.createExpertDto.photo = await encodeFileToBase64(this.createExpertDto.photo);

        await this.$axios.$post("experts", this.createExpertDto);

        await this.$parent.refreshTable();

        this.clear();

        this.$snackbar.success("Аналитик создан");
      } catch (e) {
        const html = new ApiValidationError(e, Object.keys(this.createExpertDto)).toHtml();

        this.$snackbar.error("Не удалось создать аналитика: " + html);
      } finally {
        this.loading = false;
      }
    },
    setFirmId(value) {
      this.expert.firmId = value;
    },
    clear() {
      this.expert = { ...defaultExpertDto };

      this.$refs.selectInvestCompanies.reset();

      this.$refs.form.resetValidation();
    }
  },
  components: {
    SelectInvestCompanies,
    ExpertsCreateMedia
  }
};
</script>
